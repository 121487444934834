import { FuseConfig } from '@fuse/types';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme      : 'theme-ngs-light',
    customScrollbars: true,
    layout          : {
        style    : 'vertical-layout-2',
        width    : 'fullwidth',
        // navbar   : {
        //     primaryBackground  : 'fuse-navy-700',
        //     secondaryBackground: 'fuse-navy-900',
        //     folded             : false,
        //     hidden             : false,
        //     position           : 'left',
        //     variant            : 'vertical-style-1'
        // },
        // toolbar  : {
        //     customBackgroundColor: false,
        //     background           : 'fuse-white-500',
        //     hidden               : false,
        //     position             : 'below-static'
        // },
        // footer   : {
        //     customBackgroundColor: true,
        //     background           : 'fuse-navy-900',
        //     hidden               : false,
        //     position             : 'below-fixed'
        // },
        // sidepanel: {
        //     hidden  : false,
        //     position: 'right'
        // }
        navbar   : {
            primaryBackground  : 'fuse-navy-700',
            secondaryBackground: 'fuse-navy-800',
            folded             : true,
            hidden             : false,
            position           : 'left',
            variant            : 'vertical-style-1'
        },
        toolbar  : {
            customBackgroundColor: true,
            background           : 'fuse-navy-900',
            hidden               : false,
            position             : 'below'
        },
        footer   : {
            customBackgroundColor: true,
            background           : 'fuse-navy-700',
            hidden               : false,
            position             : 'below'
        },
        sidepanel: {
            hidden  : true,
            position: 'right'
        }
    }
};

import { Injectable } from "@angular/core";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReportService } from './report.service';
import { AppPdfService } from "./appPdf.service";

@Injectable()
export class AppTeamMetricsReportPdfService {

    constructor(private _reportService: ReportService,
      
        public _appPdfService: AppPdfService
    ) {

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

  ///  withViewMetrics :true

    downloadMetricsList(formData) {

        this._reportService.getTeamReportMetrics(formData).subscribe(res => {

            if (res.reporterWiseMetrics) {
                const reporterWiseMetrics = res.reporterWiseMetrics;
                const tableBody = [];

            const metricsData: any[] = [
                { text: 'Team Metrics View Report', bold: true, fontSize: 14, lineHeight: 2, alignment: 'center' },
                {
                    style: {
                        fontSize: 10,
                        bold: true,
                        lineHeight: 1.5,
                    },
                    table: {
                       widths: ['5%','40%','20%','20%','20%'],
                        body: [
                            [
                                [{ text: 'Sr'}],
                            [{ text: 'Reporter Name'}],
                            [{ text: 'Total Story Views'}],
                            [{ text: '# Stories Created'}],
                            [{ text: '# Stories Updated'}]

                     
                            ]
                        ]
                    }
                }
            ];

                for (let i = 0; i < reporterWiseMetrics.length; i++) {
                    const repoterObj = reporterWiseMetrics[i];
                    const sr = i + 1

                   const name = repoterObj.name;
                   const viewCountDetail = repoterObj.viewCountDetail;
                   const articlesCreated = repoterObj.articlesCreated;
                   const articlesUpdated = repoterObj.articlesUpdated;
                 

                   let reporterName = '-';
                    if (name && name !== null && name !== undefined && name !== 'null') {
                        reporterName = repoterObj.name;
                    }

                    let count = '0';
                    if (viewCountDetail && viewCountDetail !== null && viewCountDetail !== undefined && viewCountDetail !== 'null') {
                        count = repoterObj.viewCountDetail;
                    }

                    let articlesCrt = '-';
                    if (articlesCreated && articlesCreated !== null && articlesCreated !== undefined && articlesCreated !== 'null') {
                        articlesCrt = repoterObj.articlesCreated;
                    }

                    let articleUpt = '-';
                    if (articlesUpdated && articlesUpdated !== null && articlesUpdated !== undefined && articlesUpdated !== 'null') {
                        articleUpt = repoterObj.articlesUpdated;
                    }
                    
                    tableBody.push(
                        [
                            sr,
                            { text: reporterName },
                            { text: count },
                            { text: articlesCrt },
                            { text: articleUpt },
                        ]
                            
                    ); 
                }


                    metricsData.push(
                        {
                            style: {
                                bold: false,
                                fontSize: 8
                            },
                            table: {
    
                                widths: ['5%', '40%','20%','20%','20%' ],
                                body: tableBody
                            }
                        }
                    
                    );


            //    const teamMetricsTable =
            //             {
            //                 style: {
            //                     bold: false,
            //                     fontSize: 8,
            //                     lineHeight: 1.5
            //                 },
            //                 table: {
            //                     widths: ['40%','20%','20%','20%'],
            //                     body: [
            //                         [

            //                         [{ text: reporterName}],
            //                         [{ text: count}],
            //                         [{ text: articlesCrt}],
            //                         [{ text: articleUpt}]
                                   
            //                         ]

                                
            //                         ]

            //                 },
            //             }

            //             metricsData.push(teamMetricsTable);
                   


                        const serviceInst = this;
                        const pdfHeader = this._appPdfService.getAppPdfHeader()

                        const dd = {
                            header: pdfHeader,
                            footer: function (currentPage: number, pageCount: number): any {
                                return serviceInst._appPdfService.getAppPdfFooter(currentPage, pageCount);
                            },

                            pageSize: 'A4',
                            pageOrientation: 'portrait',
                            content: metricsData,
                            pageMargins: [40, 70, 40, 40],

                    };

                    pdfMake.createPdf(dd).open();
                }
            }
        )};

}

export class AppApiConfig {
    //  API
    // public static APP_BASE_HREF = 'http://192.168.1.62:5800/'; // LOCAL - WORK
    public static APP_BASE_HREF = 'https://ngspress.com/'; // LIVE - PRODUCTION
    // public static APP_BASE_HREF = 'http://localhost:5800/'; // LOCAL - WORK

    // public static APP_FRONTEND_BASE_HREF = 'http://navgujaratsamaylocal.com/';
    public static APP_FRONTEND_BASE_HREF = 'https://navgujaratsamay.com/';

    //  API
    // public static API_ENDPOINT = 'http://192.168.1.62:3008/api'; // LOCAL - WORK
    // public static API_ENDPOINT = 'http://104.248.149.152:3008/api'; // LIVE - DEVELOPMENT
    //   public static API_ENDPOINT = 'http://192.168.1.62:3010/api'; // LOCAL - WORK FROM AHD-MRR-PANEL
    //  public static API_ENDPOINT = 'https://ngscms.com:3008/api'; // LIVE - PRODUCTION
     public static API_ENDPOINT = 'https://backend.navgujaratsamay.com/api'; // LIVE - PRODUCTION
    // public static API_ENDPOINT = 'http://127.0.0.1:3008/api'; // LOCAL - WORK
    // public static API_ENDPOINT = 'http://139.59.86.249:3008/api'; // LOCAL - WORK

    public static API_ROLES_URL = AppApiConfig.API_ENDPOINT + '/roles';
    public static API_ROLES_LIST_URL = AppApiConfig.API_ROLES_URL + '/table';
    public static API_ROLES_SELECT_LIST_URL = AppApiConfig.API_ROLES_URL + '/selectList';
    public static API_CHECK_ROLENAME_VALIDITY_URL = AppApiConfig.API_ROLES_URL + '/checkRoleName';
    public static API_ROLE_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_ROLES_URL + '/checkForDelete';
    public static API_ROLES_RIGHTS_LIST_URL = AppApiConfig.API_ENDPOINT + '/roles/rightPreload';
    public static API_SAVE_ROLES_RIGHTS_URL = AppApiConfig.API_ENDPOINT + '/roles/saveRights';

    public static API_MODULES_URL = AppApiConfig.API_ENDPOINT + '/modules';
    public static API_MODULES_LIST_URL = AppApiConfig.API_MODULES_URL + '/table';
    public static API_MODULES_SELECT_LIST_URL = AppApiConfig.API_MODULES_URL + '/selectList';
    public static API_CHECK_MODULENAME_VALIDITY_URL = AppApiConfig.API_MODULES_URL + '/checkModuleName';

    public static API_DEPARTMENTS_URL = AppApiConfig.API_ENDPOINT + '/departments';
    public static API_DEPARTMENTS_LIST_URL = AppApiConfig.API_DEPARTMENTS_URL + '/table';
    public static API_DEPARTMENTS_SELECT_LIST_URL = AppApiConfig.API_DEPARTMENTS_URL + '/selectList';
    public static API_CHECK_DEPARTMENTNAME_VALIDITY_URL = AppApiConfig.API_DEPARTMENTS_URL + '/checkDepartmentName';
    public static API_DEPARTMENT_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_DEPARTMENTS_URL + '/checkForDelete';

    public static API_EMPLOYEES_URL = AppApiConfig.API_ENDPOINT + '/employees';
    public static API_EMPLOYEES_LIST_URL = AppApiConfig.API_EMPLOYEES_URL + '/table';
    public static API_EMPLOYEES_SELECT_LIST_URL = AppApiConfig.API_EMPLOYEES_URL + '/selectList';
    public static API_EMPLOYEE_GENDERS_SELECT_LIST_URL = AppApiConfig.API_EMPLOYEES_URL + '/selectGenderList';
    public static API_CHECK_EMPNO_VALIDITY_URL = AppApiConfig.API_EMPLOYEES_URL + '/checkEmpNumber';
    public static API_CHECK_EMAIL_VALIDITY_URL = AppApiConfig.API_EMPLOYEES_URL + '/checkEmail';
    public static API_AUTHENTICATE_EMPLOYEE_URL = AppApiConfig.API_EMPLOYEES_URL + '/auth';
    public static API_EMPLOYEE_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_EMPLOYEES_URL + '/checkForDelete';
    public static API_EMPLOYEE_SEND_CREDENTIALS_URL = AppApiConfig.API_EMPLOYEES_URL + '/sendCredentials';
    public static API_EMPLOYEE_LOAD_PROFILE_URL = AppApiConfig.API_EMPLOYEES_URL + '/profile';
    public static API_EMPLOYEE_VALIDATE_CURRENT_PASSWORD_URL = AppApiConfig.API_EMPLOYEES_URL + '/checkPasswordValidity';
    public static API_EMPLOYEE_UPDATE_PASSWORD_URL = AppApiConfig.API_EMPLOYEES_URL + '/changePassword';
    public static API_FORGOT_EMPLOYEE_PASSWORD_URL = AppApiConfig.API_EMPLOYEES_URL + '/resetPassword';

    public static API_COUNTRIES_URL = AppApiConfig.API_ENDPOINT + '/countries';
    public static API_COUNTRIES_LIST_URL = AppApiConfig.API_COUNTRIES_URL + '/table';
    public static API_COUNTRIES_SELECT_LIST_URL = AppApiConfig.API_COUNTRIES_URL + '/selectList';
    public static API_CHECK_COUNTRYNAME_VALIDITY_URL = AppApiConfig.API_COUNTRIES_URL + '/checkCountryName';
    public static API_COUNTRY_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_COUNTRIES_URL + '/checkForDelete';

    public static API_STATES_URL = AppApiConfig.API_ENDPOINT + '/states';
    public static API_STATES_LIST_URL = AppApiConfig.API_STATES_URL + '/table';
    public static API_STATES_SELECT_LIST_URL = AppApiConfig.API_STATES_URL + '/selectList';
    public static API_CHECK_STATENAME_VALIDITY_URL = AppApiConfig.API_STATES_URL + '/checkStateName';
    public static API_STATE_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_STATES_URL + '/checkForDelete';

    public static API_CITIES_URL = AppApiConfig.API_ENDPOINT + '/cities';
    public static API_CITIES_LIST_URL = AppApiConfig.API_CITIES_URL + '/table';
    public static API_CITIES_SELECT_LIST_URL = AppApiConfig.API_CITIES_URL + '/selectList';
    public static API_CHECK_CITYNAME_VALIDITY_URL = AppApiConfig.API_CITIES_URL + '/checkCityName';
    public static API_CITY_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_CITIES_URL + '/checkForDelete';

    public static API_CATEGORIES_URL = AppApiConfig.API_ENDPOINT + '/categories';
    public static API_CATEGORIES_LIST_URL = AppApiConfig.API_CATEGORIES_URL + '/table';
    public static API_CATEGORIES_SELECT_LIST_URL = AppApiConfig.API_CATEGORIES_URL + '/selectList';
    public static API_CHECK_CATEGORIESNAME_VALIDITY_URL = AppApiConfig.API_CATEGORIES_URL + '/checkCategoryName';
    public static API_CHECK_SLUG_VALIDITY_URL = AppApiConfig.API_CATEGORIES_URL + '/checkSlug';
    public static API_CATEGORIES_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_CATEGORIES_URL + '/checkForDelete';
    public static API_SAVE_CATEGORIES_URL = AppApiConfig.API_CATEGORIES_URL + '';
    public static API_LOAD_CATEGORIES_URL = AppApiConfig.API_CATEGORIES_URL + '/detail';
    public static API_CATEGORYTYPES_SELECT_LIST_URL = AppApiConfig.API_CATEGORIES_URL + '/selectCategoryTypeList';
    public static API_CATEGORIES_SELECT_NODE_LIST_URL = AppApiConfig.API_CATEGORIES_URL + '/selectNodeList';
    public static API_CATEGORIES_SELECT_PARENT_LIST_URL = AppApiConfig.API_CATEGORIES_URL + '/selectParentList';

    public static API_POLL_URL = AppApiConfig.API_ENDPOINT + '/poll';
    public static API_SAVE_POLL_URL = AppApiConfig.API_POLL_URL + '';
    public static API_POLL_LIST_URL = AppApiConfig.API_POLL_URL + '/list';
    public static API_POLL_TABLE_LIST_URL = AppApiConfig.API_POLL_URL + '/cms/table';
    public static API_POLL_DELETE_URL = AppApiConfig.API_POLL_URL + '/cms/delete';

    public static API_AUTHORS_URL = AppApiConfig.API_ENDPOINT + '/authors';
    public static API_AUTHORS_LIST_URL = AppApiConfig.API_AUTHORS_URL + '/table';
    public static API_AUTHORS_SELECT_LIST_URL = AppApiConfig.API_AUTHORS_URL + '/selectList';
    public static API_CHECK_AUTHORNAME_VALIDITY_URL = AppApiConfig.API_AUTHORS_URL + '/checkAuthorName';
    public static API_CHECK_AUTHORSLUG_VALIDITY_URL = AppApiConfig.API_AUTHORS_URL + '/checkSlug';
    public static API_SAVE_AUTHOR_URL = AppApiConfig.API_AUTHORS_URL + '';
    public static API_LOAD_AUTHOR_URL = AppApiConfig.API_AUTHORS_URL + '/detail';
    public static API_AUTHOR_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_AUTHORS_URL + '/checkForDelete';

    public static API_ARTICLES_URL = AppApiConfig.API_ENDPOINT + '/articles';
    public static API_SAVE_ARTICLES_URL = AppApiConfig.API_ARTICLES_URL;
    public static API_ARTICLES_LIST_URL = AppApiConfig.API_ARTICLES_URL + '/table';
    public static API_ARTICLES_SELECT_LIST_URL = AppApiConfig.API_ARTICLES_URL + '/selectList';
    public static API_LOAD_ARTICLES_URL = AppApiConfig.API_ARTICLES_URL + '/detail';
    public static API_ARTICLE_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_ARTICLES_URL + '/checkForDelete';
    public static API_SAVE_ARTICLE_LIST_CHANGES_URL = AppApiConfig.API_ARTICLES_URL + '/quickSaveList';
    public static API_PERFORM_ARTICLE_LIST_OPERATIONS_URL = AppApiConfig.API_ARTICLES_URL + '/performOperationOnList';
    // public static API_ARTICLE_STATUS_SELECT_LIST_URL = AppApiConfig.API_COVERS_URL + '/selectPublishStatusList';

    /* Breaking News */
    public static API_BREAKING_NEWS_URL = AppApiConfig.API_ENDPOINT + '/breakingNews';
    public static API_SAVE_BREAKING_NEWS_URL = AppApiConfig.API_BREAKING_NEWS_URL;
    public static API_BREAKING_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_BREAKING_NEWS_URL + '/checkForDelete';
    public static API_BREAKING_NEWS_LIST_URL = AppApiConfig.API_BREAKING_NEWS_URL + '/cms/table';
    public static API_BREAKING_NEWS_DELTE_URL = AppApiConfig.API_BREAKING_NEWS_URL + '/cms/delete';

    /* Live News */
    public static API_LIVE_NEWS_URL = AppApiConfig.API_ENDPOINT + '/articles/liveNews';
    public static API_LIVE_NEWS_LIST_URL = AppApiConfig.API_LIVE_NEWS_URL + '/table';
    public static API_SAVE_LIVE_NEWS_URL = AppApiConfig.API_LIVE_NEWS_URL;
    public static API_LIVE_NEWS_DETAIL_LIST_URL = AppApiConfig.API_LIVE_NEWS_URL + '/detail/table';
    public static API_LIVE_NEWS_STATUS_URL = AppApiConfig.API_LIVE_NEWS_URL + '/status';

    public static API_COVERS_URL = AppApiConfig.API_ENDPOINT + '/covers';
    public static API_SAVE_COVERS_URL = AppApiConfig.API_COVERS_URL;
    public static API_COVERS_LIST_URL = AppApiConfig.API_COVERS_URL + '/table';
    public static API_COVERS_STATUS_SELECT_LIST_URL = AppApiConfig.API_COVERS_URL + '/selectPublishStatusList';
    public static API_LOAD_COVERS_URL = AppApiConfig.API_COVERS_URL + '/detail';
    public static API_COVER_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_COVERS_URL + '/checkForDelete';
    // public static API_CHECK_PAGENAME_VALIDITY_URL = AppApiConfig.API_COVERS_URL + '/checkPagename';

    public static API_ADS_URL = AppApiConfig.API_ENDPOINT + '/ads';
    public static API_SAVE_ADS_URL = AppApiConfig.API_ADS_URL;
    public static API_ADS_LIST_URL = AppApiConfig.API_ADS_URL + '/table';
    // public static API_ADS_PUBLICATION_SELECT_LIST_URL = AppApiConfig.API_ADS_URL + '/selectPublicationList';
    public static API_ADS_DISPLAY_PAGE_SELECT_LIST_URL = AppApiConfig.API_ADS_URL + '/selectDisplayPageList';
    public static API_ADS_DISPLAY_PAGE_LOCATION_SELECT_LIST_URL = AppApiConfig.API_ADS_URL + '/selectDisplayPageLocationList';
    public static API_LOAD_ADS_URL = AppApiConfig.API_ADS_URL + '/detail';
    public static API_AD_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_ADS_URL + '/checkForDelete';
    public static API_AD_TYPE_RADIO_LIST = AppApiConfig.API_ADS_URL + '/selectAdTypeList';
    public static API_AD_APPROVAL_STATUS_LIST = AppApiConfig.API_ADS_URL + '/selectApprovalStatusList';
    public static API_SET_APPROVAL = AppApiConfig.API_ADS_URL + '/setApproved';


    public static API_EDITIONS_URL = AppApiConfig.API_ENDPOINT + '/editions';
    public static API_SAVE_EDITIONS_URL = AppApiConfig.API_EDITIONS_URL;
    public static API_EDITIONS_LIST_URL = AppApiConfig.API_EDITIONS_URL + '/table';
    public static API_EDITIONS_SELECT_LIST_URL = AppApiConfig.API_EDITIONS_URL + '/selectList';
    public static API_LOAD_EDITIONS_URL = AppApiConfig.API_EDITIONS_URL + '/detail';
    public static API_EDITION_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_EDITIONS_URL + '/checkForDelete';
    // public static API_CHECK_PAGENAME_VALIDITY_URL = AppApiConfig.API_COVERS_URL + '/checkPagename';


    public static API_GALLERY_URL = AppApiConfig.API_ENDPOINT + '/mediaGallery';
    public static API_GALLERY_LIST_URL = AppApiConfig.API_GALLERY_URL + '/table';
    public static API_LOAD_GALLERY_URL = AppApiConfig.API_GALLERY_URL + '/detail';
    public static API_SAVE_GALLERY_URL = AppApiConfig.API_GALLERY_URL;
    public static API_MEDIA_TYPE_SELECT_LIST_URL = AppApiConfig.API_GALLERY_URL + '/selectMediaTypeList';
    public static API_MEDIA_SOURCE_SELECT_LIST_URL = AppApiConfig.API_GALLERY_URL + '/selectMediaSourceList';
    public static API_SAVE_GALLERY_IMAGE_URL = AppApiConfig.API_GALLERY_URL + '/saveImage';
    public static API_SAVE_GALLERY_VIDEO_URL = AppApiConfig.API_GALLERY_URL + '/saveVideo';
    public static API_SAVE_GALLERY_AUDIO_URL = AppApiConfig.API_GALLERY_URL + '/saveAudio';
    public static API_GALLERY_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_GALLERY_URL + '/checkForDelete';

    public static API_HOROSCOPES_URL = AppApiConfig.API_ENDPOINT + '/horoscopes';
    public static API_SAVE_HOROSCOPES_URL = AppApiConfig.API_HOROSCOPES_URL;
    public static API_HOROSCOPES_LIST_URL = AppApiConfig.API_HOROSCOPES_URL + '/table';
    public static API_LOAD_HOROSCOPES_URL = AppApiConfig.API_HOROSCOPES_URL + '/detail';
    public static API_LOAD_HOROSCOPE_SUNSIGN_LIST_URL = AppApiConfig.API_HOROSCOPES_URL + '/getSunsigns';

    /* Access Token */
    public static API_ACCESS_TOKEN_URL = AppApiConfig.API_ENDPOINT + '/accessToken';
    public static API_SAVE_ACCESS_TOKEN_URL = AppApiConfig.API_ACCESS_TOKEN_URL;
    public static API_SAVE_ACCESS_TOKEN_REF_URL = AppApiConfig.API_ACCESS_TOKEN_URL + '/update';
    public static API_ACCESS_TOKEN_LIST_URL = AppApiConfig.API_ACCESS_TOKEN_URL + '/cms/table';

    /* CMS STARTS */
    public static API_PAGES_URL = AppApiConfig.API_ENDPOINT + '/pages';
    public static API_SAVE_PAGE_URL = AppApiConfig.API_PAGES_URL;
    public static API_PAGES_LIST_URL = AppApiConfig.API_PAGES_URL + '/table';
    public static API_PAGE_STATUS_SELECT_LIST_URL = AppApiConfig.API_PAGES_URL + '/selectStatusList';
    public static API_LOAD_PAGE_URL = AppApiConfig.API_PAGES_URL + '/detail';
    public static API_CHECK_PAGENAME_VALIDITY_URL = AppApiConfig.API_PAGES_URL + '/checkPagename';
    public static API_PAGES_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_PAGES_URL + '/checkForDelete';
    /* CMS ENDS */

    public static API_PARTIES_URL = AppApiConfig.API_ENDPOINT + '/parties';
    public static API_SAVE_PARTIES_URL = AppApiConfig.API_PARTIES_URL;
    public static API_PARTIES_LIST_URL = AppApiConfig.API_PARTIES_URL + '/table';
    public static API_LOAD_PARTIES_URL = AppApiConfig.API_PARTIES_URL + '/detail';
    public static API_PARTIES_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_PARTIES_URL + '/checkForDelete';
    public static API_PARTIES_SELECT_LIST_URL = AppApiConfig.API_PARTIES_URL + '/selectList';

    public static API_STATE_PARTIES_URL = AppApiConfig.API_ENDPOINT + '/stateparties';
    public static API_SAVE_STATE_PARTIES_URL = AppApiConfig.API_STATE_PARTIES_URL;
    public static API_STATE_PARTIES_LIST_URL = AppApiConfig.API_STATE_PARTIES_URL + '/table';
    public static API_LOAD_STATE_PARTIES_URL = AppApiConfig.API_STATE_PARTIES_URL + '/detail';
    public static API_STATE_PARTIES_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_STATE_PARTIES_URL + '/checkForDelete';
    public static API_STATE_PARTIES_SELECT_LIST_URL = AppApiConfig.API_STATE_PARTIES_URL + '/selectList';
    public static API_IMPORT_STATE_PARTIES_URL = AppApiConfig.API_STATE_PARTIES_URL + '/import';

    public static API_ALLIANCES_URL = AppApiConfig.API_ENDPOINT + '/alliances';
    public static API_SAVE_ALLIANCES_URL = AppApiConfig.API_ALLIANCES_URL;
    public static API_ALLIANCES_LIST_URL = AppApiConfig.API_ALLIANCES_URL + '/table';
    public static API_LOAD_ALLIANCES_URL = AppApiConfig.API_ALLIANCES_URL + '/detail';
    public static API_ALLIANCES_SELECT_LIST_URL = AppApiConfig.API_ALLIANCES_URL + '/selectAlliancesList';

    public static API_CONSTITUENCY_TYPE_URL = AppApiConfig.API_ENDPOINT + '/constituencyType';
    public static API_SAVE_CONSTITUENCY_TYPE_URL = AppApiConfig.API_CONSTITUENCY_TYPE_URL;
    public static API_CONSTITUENCY_TYPE_LIST_URL = AppApiConfig.API_CONSTITUENCY_TYPE_URL + '/table';
    public static API_LOAD_CONSTITUENCY_TYPE_URL = AppApiConfig.API_CONSTITUENCY_TYPE_URL + '/detail';
    public static API_CONSTITUENCY_TYPE_SELECT_LIST_URL = AppApiConfig.API_CONSTITUENCY_TYPE_URL + '/selectConstituencyTypeList';

    public static API_CONTENDERS_URL = AppApiConfig.API_ENDPOINT + '/contenders';
    public static API_SAVE_CONTENDERS_URL = AppApiConfig.API_CONTENDERS_URL;
    public static API_CONTENDERS_LIST_URL = AppApiConfig.API_CONTENDERS_URL + '/table';
    public static API_LOAD_CONTENDERS_URL = AppApiConfig.API_CONTENDERS_URL + '/detail';
    public static API_CONTENDERS_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_CONTENDERS_URL + '/checkForDelete';
    public static API_CONTENDERS_SELECT_LIST_URL = AppApiConfig.API_CONTENDERS_URL + '/selectList';

    public static API_STATE_CONTENDERS_URL = AppApiConfig.API_ENDPOINT + '/statecontenders';
    public static API_SAVE_STATE_CONTENDERS_URL = AppApiConfig.API_STATE_CONTENDERS_URL;
    public static API_STATE_CONTENDERS_LIST_URL = AppApiConfig.API_STATE_CONTENDERS_URL + '/table';
    public static API_LOAD_STATE_CONTENDERS_URL = AppApiConfig.API_STATE_CONTENDERS_URL + '/detail';
    public static API_STATE_CONTENDERS_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_STATE_CONTENDERS_URL + '/checkForDelete';
    public static API_STATE_CONTENDERS_SELECT_LIST_URL = AppApiConfig.API_STATE_CONTENDERS_URL + '/selectList';
    public static API_IMPORT_STATE_CONTENDERS_URL = AppApiConfig.API_STATE_CONTENDERS_URL + '/import';

    public static API_ELECTION_CONSTITUENCIES_URL = AppApiConfig.API_ENDPOINT + '/electionconstituencies';
    public static API_SAVE_ELECTION_CONSTITUENCIES_URL = AppApiConfig.API_ELECTION_CONSTITUENCIES_URL;
    public static API_ELECTION_CONSTITUENCIES_LIST_URL = AppApiConfig.API_ELECTION_CONSTITUENCIES_URL + '/table';
    public static API_LOAD_ELECTION_CONSTITUENCIES_URL = AppApiConfig.API_ELECTION_CONSTITUENCIES_URL + '/detail';
    public static API_ELECTION_CONSTITUENCIES_SELECT_LIST_URL = AppApiConfig.API_ELECTION_CONSTITUENCIES_URL + '/selectList';
    public static API_IMPORT_ELECTION_CONSTITUENCIES_URL = AppApiConfig.API_ELECTION_CONSTITUENCIES_URL + '/import';

    public static API_ELECTIONS_URL = AppApiConfig.API_ENDPOINT + '/elections';
    public static API_SAVE_ELECTIONS_URL = AppApiConfig.API_ELECTIONS_URL;
    public static API_ELECTIONS_LIST_URL = AppApiConfig.API_ELECTIONS_URL + '/table';
    public static API_LOAD_ELECTIONS_URL = AppApiConfig.API_ELECTIONS_URL + '/detail';
    public static API_ELECTIONS_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_ELECTIONS_URL + '/checkForDelete';
    public static API_ELECTIONS_SELECT_LIST_URL = AppApiConfig.API_ELECTIONS_URL + '/selectList';
    public static API_ELECTIONS_RESULT_URL = AppApiConfig.API_ELECTIONS_URL + '/result';
    public static API_ELECTIONS_RESULT_STATUS_SELECT_LIST_URL = AppApiConfig.API_ELECTIONS_URL + '/selectResultStatusList';
    public static API_SAVE_ELECTIONS_RESULT_OVERALL_URL = AppApiConfig.API_ELECTIONS_URL + '/saveOverallResult';
    public static API_SAVE_ELECTIONS_RESULT_STATE_URL = AppApiConfig.API_ELECTIONS_URL + '/saveStateResult';
    public static API_SAVE_ELECTIONS_RESULT_CITY_URL = AppApiConfig.API_ELECTIONS_URL + '/saveCityResult';

    public static API_STATE_ELECTIONS_URL = AppApiConfig.API_ENDPOINT + '/stateelections';
    public static API_SAVE_STATE_ELECTIONS_URL = AppApiConfig.API_STATE_ELECTIONS_URL;
    public static API_IMPORT_STATE_ELECTIONS_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/import';
    public static API_STATE_ELECTIONS_LIST_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/table';
    public static API_LOAD_STATE_ELECTIONS_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/detail';
    public static API_STATE_ELECTIONS_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/checkForDelete';
    public static API_STATE_ELECTIONS_SELECT_LIST_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/selectList';
    public static API_STATE_ELECTIONS_RESULT_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/result';
    public static API_STATE_ELECTIONS_RESULT_STATUS_SELECT_LIST_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/selectResultStatusList';
    public static API_SAVE_STATE_ELECTIONS_RESULT_OVERALL_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/saveOverallResult';
    public static API_SAVE_STATE_ELECTIONS_RESULT_STATE_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/saveStateResult';
    public static API_SAVE_STATE_ELECTIONS_RESULT_CITY_URL = AppApiConfig.API_STATE_ELECTIONS_URL + '/saveCityResult';

    public static API_STATE_ELECTION_STATES_URL = AppApiConfig.API_ENDPOINT + '/stateelectionStates';
    public static API_SAVE_STATE_ELECTION_STATES_URL = AppApiConfig.API_STATE_ELECTION_STATES_URL;
    public static API_STATE_ELECTION_STATES_LIST_URL = AppApiConfig.API_STATE_ELECTION_STATES_URL + '/table';
    public static API_LOAD_STATE_ELECTION_STATES_URL = AppApiConfig.API_STATE_ELECTION_STATES_URL + '/detail';
    public static API_STATE_ELECTION_STATES_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_STATE_ELECTION_STATES_URL + '/checkForDelete';
    public static API_STATE_ELECTION_STATES_SELECT_LIST_URL = AppApiConfig.API_STATE_ELECTION_STATES_URL + '/selectList';
    public static API_IMPORT_STATE_ELECTION_STATES_URL = AppApiConfig.API_STATE_ELECTION_STATES_URL + '/import';

    public static API_CONSTITUENCIES_URL = AppApiConfig.API_ENDPOINT + '/constituencies';
    public static API_SAVE_CONSTITUENCIES_URL = AppApiConfig.API_CONSTITUENCIES_URL;
    public static API_CONSTITUENCIES_LIST_URL = AppApiConfig.API_CONSTITUENCIES_URL + '/table';
    public static API_LOAD_CONSTITUENCIES_URL = AppApiConfig.API_CONSTITUENCIES_URL + '/detail';
    public static API_CONSTITUENCIES_SELECT_LIST_URL = AppApiConfig.API_CONSTITUENCIES_URL + '/selectList';
    public static API_IMPORT_CONSTITUENCIES_URL = AppApiConfig.API_CONSTITUENCIES_URL + '/import';

    public static API_VOTES_URL = AppApiConfig.API_ENDPOINT + '/votes';
    public static API_SAVE_VOTES_URL = AppApiConfig.API_VOTES_URL;
    public static API_VOTES_LIST_URL = AppApiConfig.API_VOTES_URL + '/table';
    public static API_LOAD_VOTES_URL = AppApiConfig.API_VOTES_URL + '/detail';
    public static API_VOTES_SELECT_LIST_URL = AppApiConfig.API_VOTES_URL + '/selectList';
    public static API_IMPORT_VOTES_URL = AppApiConfig.API_VOTES_URL + '/import';

    public static API_RESULTS_URL = AppApiConfig.API_ENDPOINT + '/results';
    public static API_SAVE_RESULTS_URL = AppApiConfig.API_RESULTS_URL;
    public static API_RESULTS_LIST_URL = AppApiConfig.API_RESULTS_URL + '/table';
    public static API_LOAD_RESULTS_URL = AppApiConfig.API_RESULTS_URL + '/detail';
    public static API_IMPORT_RESULTS_URL = AppApiConfig.API_RESULTS_URL + '/import';
    public static API_EXPORT_RESULTS_URL = AppApiConfig.API_RESULTS_URL + '/table/export';

    public static API_ELECTION_STATES_URL = AppApiConfig.API_ENDPOINT + '/electionStates';
    public static API_SAVE_ELECTION_STATES_URL = AppApiConfig.API_ELECTION_STATES_URL;
    public static API_ELECTION_STATES_LIST_URL = AppApiConfig.API_ELECTION_STATES_URL + '/table';
    public static API_LOAD_ELECTION_STATES_URL = AppApiConfig.API_ELECTION_STATES_URL + '/detail';
    public static API_ELECTION_STATES_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_ELECTION_STATES_URL + '/checkForDelete';
    public static API_ELECTION_STATES_SELECT_LIST_URL = AppApiConfig.API_ELECTION_STATES_URL + '/selectList';

    public static API_ELECTION_CITIES_URL = AppApiConfig.API_ENDPOINT + '/electionCities';
    public static API_SAVE_ELECTION_CITIES_URL = AppApiConfig.API_ELECTION_CITIES_URL;
    public static API_ELECTION_CITIES_LIST_URL = AppApiConfig.API_ELECTION_CITIES_URL + '/table';
    public static API_LOAD_ELECTION_CITIES_URL = AppApiConfig.API_ELECTION_CITIES_URL + '/detail';
    public static API_ELECTION_CITIES_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_ELECTION_CITIES_URL + '/checkForDelete';
    public static API_ELECTION_CITIES_SELECT_LIST_URL = AppApiConfig.API_ELECTION_CITIES_URL + '/selectList';

    public static API_STATE_ELECTION_CITIES_URL = AppApiConfig.API_ENDPOINT + '/stateelectionCities';
    public static API_SAVE_STATE_ELECTION_CITIES_URL = AppApiConfig.API_STATE_ELECTION_CITIES_URL;
    public static API_STATE_ELECTION_CITIES_LIST_URL = AppApiConfig.API_STATE_ELECTION_CITIES_URL + '/table';
    public static API_LOAD_STATE_ELECTION_CITIES_URL = AppApiConfig.API_STATE_ELECTION_CITIES_URL + '/detail';
    public static API_STATE_ELECTION_CITIES_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_STATE_ELECTION_CITIES_URL + '/checkForDelete';
    public static API_STATE_ELECTION_CITIES_SELECT_LIST_URL = AppApiConfig.API_STATE_ELECTION_CITIES_URL + '/selectList';

    public static API_APP_BANNERS_URL = AppApiConfig.API_ENDPOINT + '/banners';
    public static API_SAVE_APP_BANNERS_URL = AppApiConfig.API_APP_BANNERS_URL;
    public static API_APP_BANNERS_LIST_URL = AppApiConfig.API_APP_BANNERS_URL + '/table';
    public static API_LOAD_APP_BANNERS_URL = AppApiConfig.API_APP_BANNERS_URL + '/detail';
    public static API_APP_BANNERS_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_APP_BANNERS_URL + '/checkForDelete';
    public static API_APP_BANNERS_SELECT_LIST_URL = AppApiConfig.API_APP_BANNERS_URL + '/selectList';

    public static API_ELECTION_CITY_RESULTS_URL = AppApiConfig.API_ENDPOINT + '/electionCityResults';
    public static API_SAVE_ELECTION_CITY_RESULTS_URL = AppApiConfig.API_ELECTION_CITY_RESULTS_URL;
    public static API_ELECTION_CITY_RESULTS_LIST_URL = AppApiConfig.API_ELECTION_CITY_RESULTS_URL + '/table';
    public static API_LOAD_ELECTION_CITY_RESULTS_URL = AppApiConfig.API_ELECTION_CITY_RESULTS_URL + '/detail';
    public static API_ELECTION_CITY_RESULTS_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_ELECTION_CITY_RESULTS_URL + '/checkForDelete';

    public static API_STATE_ELECTION_CITY_RESULTS_URL = AppApiConfig.API_ENDPOINT + '/stateelectionCityResults';
    public static API_SAVE_STATE_ELECTION_CITY_RESULTS_URL = AppApiConfig.API_STATE_ELECTION_CITY_RESULTS_URL;
    public static API_STATE_ELECTION_CITY_RESULTS_LIST_URL = AppApiConfig.API_STATE_ELECTION_CITY_RESULTS_URL + '/table';
    public static API_LOAD_STATE_ELECTION_CITY_RESULTS_URL = AppApiConfig.API_STATE_ELECTION_CITY_RESULTS_URL + '/detail';
    public static API_STATE_ELECTION_CITY_RESULTS_CHECK_CAN_BE_DELETED_URL = AppApiConfig.API_STATE_ELECTION_CITY_RESULTS_URL + '/checkForDelete';

    public static API_APP_REPORTS_URL = AppApiConfig.API_ENDPOINT + '/appReports';
    public static API_APP_REPORTS_SELF_METRICS_URL = AppApiConfig.API_APP_REPORTS_URL + '/selfMetrics';
    public static API_APP_REPORTS_TEAM_METRICS_URL = AppApiConfig.API_APP_REPORTS_URL + '/teamMetrics';

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { url } from 'inspector';
import { UserSessionService } from './user-session.service';
import { AppApiConfig } from 'assets/config/app-api.config';

@Injectable()
export class AccessTokenService
{
  accessToken: any[];
  onAccessTokenChanged: BehaviorSubject<any> = new BehaviorSubject({});
  reqHeaders;

  constructor(
    private _http: HttpClient,
    private _sessionService: UserSessionService
  )
  {
    this.reqHeaders = this._sessionService.getSessionHeaders();
  }

  setAccessToken(formData): Observable<any>{
    const reqParams = formData;
    const reqHeaders = this._sessionService.getSessionHeaders();
    return this._http.post(AppApiConfig.API_SAVE_ACCESS_TOKEN_URL, reqParams, { headers: reqHeaders });
  }
  setAccessTokenRef(formData): Observable<any>{
    const reqParams = formData;
    const reqHeaders = this._sessionService.getSessionHeaders();
    return this._http.post(AppApiConfig.API_SAVE_ACCESS_TOKEN_REF_URL, reqParams, { headers: reqHeaders });
  }


  getAccessToken(): Observable<any>{
    const reqParams = { };
    const reqHeaders = this._sessionService.getSessionHeaders();
    return this._http.post(AppApiConfig.API_ACCESS_TOKEN_LIST_URL, reqParams, { headers: reqHeaders });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { url } from 'inspector';
import { UserSessionService } from './user-session.service';
import { AppApiConfig } from 'assets/config/app-api.config';

@Injectable()
export class ArticleViewsReportService {
    articleViewsReport: any[];
    onArticleViewsReportChanged: BehaviorSubject<any> = new BehaviorSubject({});
    reqHeaders;

    constructor(
        private _http: HttpClient,
        private _sessionService: UserSessionService
    ) {
        this.reqHeaders = this._sessionService.getSessionHeaders();
    }

    getArticleTableList(formData): Observable<any> {
        const reqParams = formData;
        return this._http.post(AppApiConfig.API_ARTICLES_LIST_URL, reqParams, { headers: this.reqHeaders });
    }

    getArticleViewsTableList(formData): Observable<any> {
        const reqParams = formData;
        reqParams.withViewMetrics = true;
        return this.getArticleTableList(formData);
    }

    getPublishStatusList(): Observable<any> {
        const reqParams = {};
        return this._http.post(
            AppApiConfig.API_COVERS_STATUS_SELECT_LIST_URL,
            reqParams,
            { headers: this.reqHeaders }
        );
    }
    getArticleViewsDetails(withViewMetrics): Observable<any>{
        const reqParams = { withViewMetrics: true};
       // const reqHeaders = this.empService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_ARTICLES_LIST_URL, reqParams, { headers: this.reqHeaders });
      }

      

}

import { UserSessionService } from './user-session.service';

const MINUTES_UNITL_AUTO_LOGOUT = 6 * 60; // in mins
const CHECK_INTERVAL = 1000 * 60; // in ms
const STORE_KEY =  'lastAction';

export class AutoLogoutService {

  appuserService: UserSessionService;

  get lastAction() {
    // tslint:disable-next-line:radix
    return parseInt(localStorage.getItem(STORE_KEY));
  }

  set lastAction(value) {
    localStorage.setItem(STORE_KEY, value + '');
  }

  constructor(appuserService) {
    this.appuserService = appuserService;
    this.check();
    this.initListener();
    this.initInterval();
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
  }

  reset() {
    this.lastAction = Date.now();
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout && this.appuserService.getUserLoggedInStatus()) {
      this.appuserService.performUserLogout();
      location.reload();
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS operator for mapping the observable

import 'rxjs/add/operator/map';
import { UserSessionService } from './user-session.service';
import { AppApiConfig } from 'assets/config/app-api.config';
import { AppConfig } from 'assets/config/app.config';
import { UserRightsModel } from 'app/models/user-rights.model';
import { EmployeeModel } from 'app/models/employee.model';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class EmployeeService {

    timestampToDispDate(arg0: any): any {
        throw new Error('Method not implemented.');
    }
    dateToTimestamp(arg0: any): any {
        throw new Error('Method not implemented.');
    }

    constructor(
        private http: HttpClient,
        private sessionService: UserSessionService
    ) {
    }

    createEmployee(employee): Observable<any> {
        const reqHeaders = this.sessionService.getSessionHeaders();
        return this.http.post(AppApiConfig.API_EMPLOYEES_URL, employee, { headers: reqHeaders });
    }

    authenticateEmployee(empId: string, password: string): Observable<any> {
        const reqHeaders = this.sessionService.getSessionHeaders();
        const reqParams = { empId: empId, password: password };
        return this.http.patch(AppApiConfig.API_AUTHENTICATE_EMPLOYEE_URL, reqParams, { headers: reqHeaders });
    }

    resetEmployeePassword(empId: string): Observable<any> {
        const reqHeaders = this.sessionService.getSessionHeaders();
        const reqParams = { empId: empId };
        return this.http.post(AppApiConfig.API_FORGOT_EMPLOYEE_PASSWORD_URL, reqParams, { headers: reqHeaders });
    }

    sendEmployeeCredentials(id: string): Observable<any> {
        const reqHeaders = this.sessionService.getSessionHeaders();
        const reqParams = { '_id': id };
        return this.http.post(AppApiConfig.API_EMPLOYEE_SEND_CREDENTIALS_URL, reqParams, { headers: reqHeaders });
    }

    
  checkEmployeeValidity(empId: string, empNo: string): Observable<any>{
    const reqParams = { '_id': empId, 'empNo': empNo};
    const reqHeaders = this.sessionService.getSessionHeaders();
    return this.http.post(AppApiConfig.API_CHECK_EMPNO_VALIDITY_URL, reqParams, { headers: reqHeaders });
  }

    updateEmployee(employee): Promise<any>
    {
      return new Promise((resolve, reject) => {
        const reqHeaders = this.sessionService.getSessionHeaders();
        this.http.put(AppApiConfig.API_EMPLOYEES_URL, employee, { headers: reqHeaders })
          .subscribe(response => {

            resolve(response);
          });
      });
    }

    performUserLogout(): void {
        this.sessionService.removeUserSession();
        return;
    }

    // Default Error handling method.
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    getEmployeeDatatableUrl(): string {
        return AppApiConfig.API_EMPLOYEES_LIST_URL;
    }

    getEmployeesList(): Observable<any>{
        const reqHeaders = this.sessionService.getSessionHeaders();
        const reqParams = {  };
        return this.http.post(AppApiConfig.API_EMPLOYEES_SELECT_LIST_URL, reqParams, { headers: reqHeaders });
    }

    invertActiveStatus(employee): Promise<any>
    {
        return new Promise((resolve, reject) => {
            const reqHeaders = this.sessionService.getSessionHeaders();
            const reqParams = {  };
        this.http.patch(AppApiConfig.API_EMPLOYEES_URL, employee, { headers: reqHeaders })
            .subscribe(response => {
            resolve(response);
            });
        });
    }

    setUserSession(empId: string, empName: string, empKey: string, empRights: UserRightsModel[], empEmail: string): void {
        const usrEmpNoKey = AppConfig.SESS_USER_EMPLOYEE_NO;
        const usrNameKey = AppConfig.SESS_USER_NAME;
        const usrIdKey = AppConfig.SESS_USER_KEY;
        const usrEmailKey = AppConfig.SESS_USER_EMAIL;
        const usrRightsKey = AppConfig.SESS_USER_RIGHTS;
        const myObj = { [usrEmpNoKey]: empId, [usrNameKey]: empName, [usrIdKey]: empKey, [usrEmailKey]: empEmail, [usrRightsKey]: empRights};

        localStorage.setItem( AppConfig.SESSION_USER_INFO, JSON.stringify(myObj) );
        localStorage.setItem( AppConfig.SESSSION_IS_USER_LOGGED_IN, '1');
    return;
    }

    updateCurrentPassword(formData): Observable<any> {
        const reqHeaders = this.sessionService.getSessionHeaders();
        const reqParams = formData;
        return this.http.post(AppApiConfig.API_EMPLOYEE_UPDATE_PASSWORD_URL, reqParams, { headers: reqHeaders });
    }

    loadProfile(): Observable<any> {
        const reqHeaders = this.sessionService.getSessionHeaders();
        const reqParams = { };
        return this.http.post(AppApiConfig.API_EMPLOYEE_LOAD_PROFILE_URL, reqParams, { headers: reqHeaders });
    }

    checkCurrentPasswordValidity(password: string): Observable<any> {
        const reqHeaders = this.sessionService.getSessionHeaders();
        const reqParams = { password: password };
        return this.http.post(AppApiConfig.API_EMPLOYEE_VALIDATE_CURRENT_PASSWORD_URL, reqParams, { headers: reqHeaders });
    }

    checkEmployeeCanBeDeleted(employee): Observable<any> {
        const reqParams = { '_id': employee._id };
        const reqHeaders = this.sessionService.getSessionHeaders();
        return this.http.post(AppApiConfig.API_EMPLOYEE_CHECK_CAN_BE_DELETED_URL, reqParams, { headers: reqHeaders });
    }

    getGendersList(): Observable<any> {
        const reqParams = {  };
        const reqHeaders = this.sessionService.getSessionHeaders();
        return this.http.post(AppApiConfig.API_EMPLOYEE_GENDERS_SELECT_LIST_URL, reqParams, { headers: reqHeaders });
    }

    deleteEmployee(employee): Promise<any>
    {
        const reqHeaders = this.sessionService.getSessionHeaders();
        return new Promise((resolve, reject) => {

        this.http.delete(AppApiConfig.API_EMPLOYEES_URL + '/' + employee._id, { headers: reqHeaders })
            .subscribe(response => {

            resolve(response);
            });
        });
    }

}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { url } from "inspector";
import { UserSessionService } from "./user-session.service";
import { AppApiConfig } from "assets/config/app-api.config";

@Injectable()
export class ReportService {

    constructor(
        private _http: HttpClient,
        private _sessionService: UserSessionService
    ) {
    }

    getSelfReportMetrics(): Observable<any> {
        const reqParams = {};
        const reqHeaders = this._sessionService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_APP_REPORTS_SELF_METRICS_URL, reqParams, { headers: reqHeaders });
    }

    getTeamReportMetrics(formData): Observable<any> {
        const reqParams = formData;
        const reqHeaders = this._sessionService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_APP_REPORTS_TEAM_METRICS_URL, reqParams, { headers: reqHeaders });
    }
}

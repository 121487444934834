import { Component } from '@angular/core';
import { AppConfig } from 'assets/config/app.config';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
    vendorName = AppConfig.VENDOR_NAME;
    vendorLink = AppConfig.VENDOR_WEBSITE_URL;
    /**
     * Constructor
     */
    constructor()
    {
    }
}


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstConfig } from 'assets/config/app-const.config';
import { AppConfig } from 'assets/config/app.config';
import { AppApiConfig } from 'assets/config/app-api.config';

@Injectable()
export class CommonRoutingService
{
    constructor(
        private _router: Router
    ) {

    }

    goToLogin(): void {
        this._router.navigate(['/login']);
        return;
    }

    goToHome(): void {
        this._router.navigate(['/dashboard']);
        return;
    }

    goToAccessDenied(): void {
        this._router.navigate(['/access-denied']);
        return;
    }

    goToPartyList(): void {
        this._router.navigate(['/parties']);
        return;
    }
    goToStatePartyList(): void {
        this._router.navigate(['/stateparties']);
        return;
    }

    goToPartyDetails(isView: number, id: string): void {
        this._router.navigate(['/party'], { queryParams : { id : id, isView : isView }});
        return;
    }
    goToStatePartyDetails(isView: number, id: string): void {
        this._router.navigate(['/stateparty'], { queryParams : { id : id, isView : isView }});
        return;
    }
    goToAllianceList(): void {
        this._router.navigate(['/alliances']);
        return;
    }

    goToConstituencyTypeList(): void {
        this._router.navigate(['/constituencyType']);
        return;
    }

    goToAddAlliance(isView: number, id: string): void {
        this._router.navigate(['/addalliance'], { queryParams : { id : id, isView : isView }});
        return;
    }

    goToContenderList(): void {
        this._router.navigate(['/contenders']);
        return;
    }
    goToStateContenderList(): void {
        this._router.navigate(['/statecontenders']);
        return;
    }

    goToElectionConstituenciesList(): void {
        this._router.navigate(['/electionconstituencies']);
        return;
    }

    goToElectionConstituencyDetails(isView: number, id: string): void {
        this._router.navigate(['/addelectionconstituencies'], { queryParams : { id : id, isView : isView }});
        return;
    }

    goToContenderDetails(isView: number, id: string): void {
        this._router.navigate(['/contender'], { queryParams : { id : id, isView : isView }});
        return;
    }
    goToStateContenderDetails(isView: number, id: string): void {
        this._router.navigate(['/statecontender'], { queryParams : { id : id, isView : isView }});
        return;
    }

    goToElectionStateList(): void {
        this._router.navigate(['/electionStates']);
        return;
    }

    goToElectionCityList(): void {
        this._router.navigate(['/electionCities']);
        return;
    }

    goToElectionList(): void {
        this._router.navigate(['/elections']);
        return;
    }

    goToElectionResult(id: string): void {
        this._router.navigate(['/electionResult'], { queryParams : { id : id }});
        return;
    }
    goToStateElectionResult(id: string): void {
        this._router.navigate(['/stateelectionResult'], { queryParams : { id : id }});
        return;
    }

    goToAppBannerList(): void {
        this._router.navigate(['/appBanners']);
        return;
    }

    goToAppBannerDetails(isView: number, id: string): void {
        this._router.navigate(['/appBanner'], { queryParams : { id : id, isView : isView }});
        return;
    }

    goToElectionCityResultList(): void {
        this._router.navigate(['/electionCityResults']);
        return;
    }

    goToArticleDetails(id: any, isView: number,inNewTab: boolean = false): void {
        let consUrl = '/articleDetail';
        let queryParamMap = {};
        if (inNewTab === true)
        {
            consUrl += '?id=' + id + '&isView=' + isView;
        }
        else
        {
            queryParamMap = { id : id, isView : isView };
        }

        this.goToRelevantPage(inNewTab, consUrl, queryParamMap);
        return;
    }

    goToArticleModify(id: string, isView:number, inNewTab: boolean = false): void {
        let consUrl = '/article';
        let queryParamMap: any = {};
        if (id !== undefined && id !== '') {
            queryParamMap.id = id;
        }
        if (inNewTab === true) {
            if (id !== undefined && id !== '') {
                consUrl += '?id=' + id + '&isView=' + isView;
            }
        }
        this.goToRelevantPage(inNewTab, consUrl, queryParamMap);
        return;
    }

    goToLiveArticleModify(id: string, isView:number, inNewTab: boolean = false): void {
        let consUrl = '/liveNewsForm';
        let queryParamMap: any = {};
        if (id !== undefined && id !== '') {
            queryParamMap.id = id;
        }
        if (inNewTab === true) {
            if (id !== undefined && id !== '') {
                consUrl += '?id=' + id + '&isView=' + isView;
            }
        }
        this.goToRelevantPage(inNewTab, consUrl, queryParamMap);
        return;
    }

    goToRelevantPage(inNewTab: boolean, url: string, queryParamMap: any): void {
        if (inNewTab === true)
        {
            const urlWithBase = AppApiConfig.APP_BASE_HREF + url;
            const win = window.open(urlWithBase, '_blank');
            win.focus();
            return;
        }
        else
        {
            this._router.navigate([url], { queryParams : queryParamMap });
            return;
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({name: 'sanitizeResourceUrl'})
export class SanitizeResourceUrlPipe implements PipeTransform
{
    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string): string
    {
        let formattedValue; // = value;

        if (value && value !== '') {
            formattedValue = this.sanitizer.bypassSecurityTrustResourceUrl(value);
        }

        return formattedValue;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { url } from 'inspector';
import { UserSessionService } from './user-session.service';
import { AppApiConfig } from 'assets/config/app-api.config';

@Injectable()
export class BreakingNewsService
{
  breakingNews: any[];
  onBreakingNewsChanged: BehaviorSubject<any> = new BehaviorSubject({});
  reqHeaders;

  constructor(
    private _http: HttpClient,
    private _sessionService: UserSessionService
  )
  {
    this.reqHeaders = this._sessionService.getSessionHeaders();
  }

  setBreakingNews(formData): Observable<any>{
    const reqParams = formData;
    const reqHeaders = this._sessionService.getSessionHeaders();
    return this._http.post(AppApiConfig.API_SAVE_BREAKING_NEWS_URL, reqParams, { headers: reqHeaders });
  }

    setPoll(formData): Observable<any>{
        const reqParams = formData;
        const reqHeaders = this._sessionService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_SAVE_POLL_URL, reqParams, { headers: reqHeaders });
    }


  getBreakingNews(): Observable<any>{
    const reqParams = { };
    const reqHeaders = this._sessionService.getSessionHeaders();
    return this._http.post(AppApiConfig.API_BREAKING_NEWS_LIST_URL, reqParams, { headers: reqHeaders });
  }

    getPoll(): Observable<any>{
        const reqParams = { };
        const reqHeaders = this._sessionService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_POLL_LIST_URL, reqParams, { headers: reqHeaders });
    }

  /*loadSunsign(): Observable<any> {
    const reqParams = {  };
    return this._http.post(AppApiConfig.API_LOAD_HOROSCOPE_SUNSIGN_LIST_URL, reqParams, { headers: this.reqHeaders });
  }*/

    deleteBN(bnID): Promise<any> {
        return new Promise((resolve, reject) => {

            this._http.delete(AppApiConfig.API_BREAKING_NEWS_URL + '/' + bnID, { headers: this.reqHeaders })
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    pollDeleteBN(bnID): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.delete(AppApiConfig.API_POLL_URL + '/' + bnID, { headers: this.reqHeaders })
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

}

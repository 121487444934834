import {Injectable} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { ArticleViewsReportService } from './articleViewsReport.service';
import { ReportService } from './report.service';
import { AppCommonService } from './app-common.service';

@Injectable()
export class AppPdfService {

  constructor(
      private _articleViewReportService: ArticleViewsReportService,
      private _reportService: ReportService,
      public datepipe: DatePipe,
      public _appCommonService: AppCommonService
      ) {
     pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }
pdfMake(): void {
    const contentArr = [];
    for (let i = 0; i < 5; i++) {
        contentArr.push(i + ' Val');
    }

    const dd = {
        content: contentArr
    };

    pdfMake.createPdf(dd).open();
}

getAppPdfHeader(): any {
    const pdfHeader = {
        columns: [{
            table: {
              //  widths: ['15%', '65%', '20%'],
                body: [
                    [ {
                        table: {

                            style: {
                                fontSize: 10,
                                bold: false,
                                lineHeight: 1

                            },
                            body: [


                                [{ text: 'Navgujarat Samay', bold: true, fontSize: 10 }],
                                [{ text: 'Phone: +91 756 777 6556', bold: true, fontSize: 10 }],
                                [{ text: 'Email: info@ngs.co.in', bold: true, fontSize: 10 }],
                                [{ text: 'Website: www.ngs.co.in', bold: true, fontSize: 10 }],
                             ]
                        },
                        layout: 'noBorders'
                    },
                    {
                        // image: AppBase64Config.COMPANY_HEADER_IMAGE,
                        // fit:[50,50],
                        // alignment: 'right'
                    }],
                ]
            },
            layout: 'noBorders'
        },
            // [''],
        ],
        layout: 'noBorders',
        margin: [40, 10, 40, 10]
    };

    return pdfHeader;

}

getAppPdfFooter(currentPage: number, pageCount: number): any {

    const columns = [{

        table: {
            widths: ['30%', '40%', '30%'],
            style: {
                fontSize: 8,
                bold: false,
                italics: true,

            },
            body: [
                [

                    {
                        text: 'Powered By EterPride',
                        alignment: 'left',
                        fontSize: 8,
                        italics: true,
                    },
                    {
                        text: 'Page ' + currentPage + ' of ' + pageCount,
                        alignment: 'center',
                        fontSize: 8,
                        italics: true,
                    },
                    {
                        text: 'Printed on ' + this._appCommonService.getCurrentDateTimeString(),
                        alignment: 'right',
                        fontSize: 8,
                        italics: true,
                    },

                ],


            ]
        },
        layout: 'noBorders',
        margin: [40, 0, 40, 0]
    }

    ];


    return columns;

}



}
